import React from 'react';
import classnames from 'classnames';

import * as styles from './section.module.scss';

interface Props {
  name: string;
  title?: string;
  sectionClassNames?: string;
  contentClassNames?: string;
  containerClassNames?: string;
  children: React.ReactNode;
  titleClassName?: string;
}

const Section = (props: Props) => {
  const {
    name,
    title,
    sectionClassNames,
    contentClassNames,
    containerClassNames,
    children,
    titleClassName,
  } = props;

  const sectionClasses = classnames(styles.section, sectionClassNames);
  const containerClasses = classnames(styles.container, containerClassNames);

  return (
    <section id={name} className={sectionClasses}>
      <div className={containerClasses}>
        {title && <h2 className={classnames([styles.title, titleClassName])}>{title}</h2>}
        <div className={contentClassNames}>{children}</div>
      </div>
    </section>
  );
};

export default Section;

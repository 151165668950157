import React from 'react';

import Section from '@heureca/shared/components/Section';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import * as styles from './conditions.module.scss';

const Conditions = () => {
  const { translate } = useTranslations();

  return (
    <Section name={translate('conditions.title')} title={translate('conditions.title')}>
      <div className={styles.content}>
        <p>{translate('conditions.text')}</p>

        <h1>{translate('conditions.who.title')}</h1>
        <p>{translate('conditions.who.text')}</p>
        <ul>
          <li>Heureca Belgium SRL</li>
          <li>Rue Comte Cornet 19, 7022 Maisières</li>
          <li>Numéro d’entreprise : BE0778.451.229</li>
          <li>E-mail : hello@heureca.eu</li>
        </ul>
        <p>{translate('conditions.who.endText')}</p>

        <h1>{translate('conditions.ourWebsite.title')}</h1>
        <h2>{translate('conditions.ourWebsite.accessibility')}</h2>
        <p>{translate('conditions.ourWebsite.accessibilityText')}</p>
        <h2>{translate('conditions.ourWebsite.content')}</h2>
        <p>{translate('conditions.ourWebsite.contentText')}</p>
        <h2>{translate('conditions.ourWebsite.user')}</h2>
        <p>{translate('conditions.ourWebsite.userText')}</p>

        <h1>{translate('conditions.otherWebsite.title')}</h1>
        <p>{translate('conditions.otherWebsite.text')}</p>

        <h1>{translate('conditions.intellectualProperty.title')}</h1>
        <p>{translate('conditions.intellectualProperty.text')}</p>

        <h1>{translate('conditions.dataProtection.title')}</h1>
        <p>
          {translate('conditions.dataProtection.text')}
          {' '}
          <a href="/private">
            {translate('conditions.dataProtection.link')}
          </a>
        </p>

        <h1>{translate('conditions.UGC.title')}</h1>
        <p>{translate('conditions.UGC.text')}</p>
      </div>
    </Section>
  );
};

export default Conditions;
